@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.rotating-element {
    animation: rotate 10s linear infinite;
}

.rotating-element--slow {
    animation: rotate 180s linear infinite;
}