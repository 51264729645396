@font-face {
    font-family: 'Ezra';
    src: url('../../assets/fonts/Ezra-Black.eot');
    src: url('../../assets/fonts/Ezra-Black.eot?#iefix') format('embedded-opentype'),
        url('../../assets/fonts/Ezra-Black.woff2') format('woff2'),
        url('../../assets/fonts/Ezra-Black.woff') format('woff'),
        url('../../assets/fonts/Ezra-Black.ttf') format('truetype'),
        url('../../assets/fonts/Ezra-Black.svg#Ezra-Black') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Ezra';
    src: url('../../assets/fonts/Ezra-Bold.eot');
    src: url('../../assets/fonts/Ezra-Bold.eot?#iefix') format('embedded-opentype'),
        url('../../assets/fonts/Ezra-Bold.woff2') format('woff2'),
        url('../../assets/fonts/Ezra-Bold.woff') format('woff'),
        url('../../assets/fonts/Ezra-Bold.ttf') format('truetype'),
        url('../../assets/fonts/Ezra-Bold.svg#Ezra-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Ezra';
    src: url('../../assets/fonts/Ezra-BlackItalic.eot');
    src: url('../../assets/fonts/Ezra-BlackItalic.eot?#iefix') format('embedded-opentype'),
        url('../../assets/fonts/Ezra-BlackItalic.woff2') format('woff2'),
        url('../../assets/fonts/Ezra-BlackItalic.woff') format('woff'),
        url('../../assets/fonts/Ezra-BlackItalic.ttf') format('truetype'),
        url('../../assets/fonts/Ezra-BlackItalic.svg#Ezra-BlackItalic') format('svg');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Ezra';
    src: url('../../assets/fonts/Ezra-BoldItalic.eot');
    src: url('../../assets/fonts/Ezra-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('../../assets/fonts/Ezra-BoldItalic.woff2') format('woff2'),
        url('../../assets/fonts/Ezra-BoldItalic.woff') format('woff'),
        url('../../assets/fonts/Ezra-BoldItalic.ttf') format('truetype'),
        url('../../assets/fonts/Ezra-BoldItalic.svg#Ezra-BoldItalic') format('svg');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Ezra ExtBd';
    src: url('../../assets/fonts/Ezra-ExtraBoldItalic.eot');
    src: url('../../assets/fonts/Ezra-ExtraBoldItalic.eot?#iefix') format('embedded-opentype'),
        url('../../assets/fonts/Ezra-ExtraBoldItalic.woff2') format('woff2'),
        url('../../assets/fonts/Ezra-ExtraBoldItalic.woff') format('woff'),
        url('../../assets/fonts/Ezra-ExtraBoldItalic.ttf') format('truetype'),
        url('../../assets/fonts/Ezra-ExtraBoldItalic.svg#Ezra-ExtraBoldItalic') format('svg');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Ezra ExtBd';
    src: url('../../assets/fonts/Ezra-ExtraBold.eot');
    src: url('../../assets/fonts/Ezra-ExtraBold.eot?#iefix') format('embedded-opentype'),
        url('../../assets/fonts/Ezra-ExtraBold.woff2') format('woff2'),
        url('../../assets/fonts/Ezra-ExtraBold.woff') format('woff'),
        url('../../assets/fonts/Ezra-ExtraBold.ttf') format('truetype'),
        url('../../assets/fonts/Ezra-ExtraBold.svg#Ezra-ExtraBold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Ezra';
    src: url('../../assets/fonts/Ezra-Light.eot');
    src: url('../../assets/fonts/Ezra-Light.eot?#iefix') format('embedded-opentype'),
        url('../../assets/fonts/Ezra-Light.woff2') format('woff2'),
        url('../../assets/fonts/Ezra-Light.woff') format('woff'),
        url('../../assets/fonts/Ezra-Light.ttf') format('truetype'),
        url('../../assets/fonts/Ezra-Light.svg#Ezra-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Ezra';
    src: url('../../assets/fonts/Ezra-LightItalic.eot');
    src: url('../../assets/fonts/Ezra-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('../../assets/fonts/Ezra-LightItalic.woff2') format('woff2'),
        url('../../assets/fonts/Ezra-LightItalic.woff') format('woff'),
        url('../../assets/fonts/Ezra-LightItalic.ttf') format('truetype'),
        url('../../assets/fonts/Ezra-LightItalic.svg#Ezra-LightItalic') format('svg');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Ezra';
    src: url('../../assets/fonts/Ezra-Italic.eot');
    src: url('../../assets/fonts/Ezra-Italic.eot?#iefix') format('embedded-opentype'),
        url('../../assets/fonts/Ezra-Italic.woff2') format('woff2'),
        url('../../assets/fonts/Ezra-Italic.woff') format('woff'),
        url('../../assets/fonts/Ezra-Italic.ttf') format('truetype'),
        url('../../assets/fonts/Ezra-Italic.svg#Ezra-Italic') format('svg');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Ezra Med';
    src: url('../../assets/fonts/Ezra-Medium.eot');
    src: url('../../assets/fonts/Ezra-Medium.eot?#iefix') format('embedded-opentype'),
        url('../../assets/fonts/Ezra-Medium.woff2') format('woff2'),
        url('../../assets/fonts/Ezra-Medium.woff') format('woff'),
        url('../../assets/fonts/Ezra-Medium.ttf') format('truetype'),
        url('../../assets/fonts/Ezra-Medium.svg#Ezra-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Ezra Med';
    src: url('../../assets/fonts/Ezra-MediumItalic.eot');
    src: url('../../assets/fonts/Ezra-MediumItalic.eot?#iefix') format('embedded-opentype'),
        url('../../assets/fonts/Ezra-MediumItalic.woff2') format('woff2'),
        url('../../assets/fonts/Ezra-MediumItalic.woff') format('woff'),
        url('../../assets/fonts/Ezra-MediumItalic.ttf') format('truetype'),
        url('../../assets/fonts/Ezra-MediumItalic.svg#Ezra-MediumItalic') format('svg');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Ezra';
    src: url('../../assets/fonts/Ezra-Regular.eot');
    src: url('../../assets/fonts/Ezra-Regular.eot?#iefix') format('embedded-opentype'),
        url('../../assets/fonts/Ezra-Regular.woff2') format('woff2'),
        url('../../assets/fonts/Ezra-Regular.woff') format('woff'),
        url('../../assets/fonts/Ezra-Regular.ttf') format('truetype'),
        url('../../assets/fonts/Ezra-Regular.svg#Ezra-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Ezra SemBd';
    src: url('../../assets/fonts/Ezra-SemiBold.eot');
    src: url('../../assets/fonts/Ezra-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('../../assets/fonts/Ezra-SemiBold.woff2') format('woff2'),
        url('../../assets/fonts/Ezra-SemiBold.woff') format('woff'),
        url('../../assets/fonts/Ezra-SemiBold.ttf') format('truetype'),
        url('../../assets/fonts/Ezra-SemiBold.svg#Ezra-SemiBold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Ezra SemBd';
    src: url('../../assets/fonts/Ezra-SemiBoldItalic.eot');
    src: url('../../assets/fonts/Ezra-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
        url('../../assets/fonts/Ezra-SemiBoldItalic.woff2') format('woff2'),
        url('../../assets/fonts/Ezra-SemiBoldItalic.woff') format('woff'),
        url('../../assets/fonts/Ezra-SemiBoldItalic.ttf') format('truetype'),
        url('../../assets/fonts/Ezra-SemiBoldItalic.svg#Ezra-SemiBoldItalic') format('svg');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Ezra';
    src: url('../../assets/fonts/Ezra-Black_1.eot');
    src: url('../../assets/fonts/Ezra-Black_1.eot?#iefix') format('embedded-opentype'),
        url('../../assets/fonts/Ezra-Black_1.woff2') format('woff2'),
        url('../../assets/fonts/Ezra-Black_1.woff') format('woff'),
        url('../../assets/fonts/Ezra-Black_1.ttf') format('truetype'),
        url('../../assets/fonts/Ezra-Black_1.svg#Ezra-Black') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Ezra';
    src: url('../../assets/fonts/Ezra-BlackItalic_1.eot');
    src: url('../../assets/fonts/Ezra-BlackItalic_1.eot?#iefix') format('embedded-opentype'),
        url('../../assets/fonts/Ezra-BlackItalic_1.woff2') format('woff2'),
        url('../../assets/fonts/Ezra-BlackItalic_1.woff') format('woff'),
        url('../../assets/fonts/Ezra-BlackItalic_1.ttf') format('truetype'),
        url('../../assets/fonts/Ezra-BlackItalic_1.svg#Ezra-BlackItalic') format('svg');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Ezra';
    src: url('../../assets/fonts/Ezra-Bold_1.eot');
    src: url('../../assets/fonts/Ezra-Bold_1.eot?#iefix') format('embedded-opentype'),
        url('../../assets/fonts/Ezra-Bold_1.woff2') format('woff2'),
        url('../../assets/fonts/Ezra-Bold_1.woff') format('woff'),
        url('../../assets/fonts/Ezra-Bold_1.ttf') format('truetype'),
        url('../../assets/fonts/Ezra-Bold_1.svg#Ezra-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

/* body,
html {
    transform: translate3d(0, 0, 0);
} */